@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {
  .card {
    @apply bg-gray-50 border-2 border-gray-100 rounded-lg p-[16px]
  }
  .card2 {
    @apply bg-gray-50 border shadow-md rounded-lg p-[16px]
  }
  .but1 {
    @apply bg-gray-800 py-2 px-5 rounded-sm text-sm font-semibold
  }
}

